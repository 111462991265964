
import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import { UFliterals } from "@/utils/literals";
import { IUser } from "@/schemas/IUser";
import WebSocketManager from "@/utils/websocketManager";
import { webSocketUrl } from "@/env";
import { mapActions, mapMutations, mapState } from "vuex";

@Component({ name: "ProgressTimer",
  computed: {
    ...mapState("user", ["userId"]),
    ...mapState("session", ["users"]),
  },
  methods: {
    ...mapMutations({ setSnack: "SET_SNACK" }),
    ...mapActions("session", ["getUserWhoAgreedOnSessionEnd"]),
  },})
export default class ProgressTimer extends Vue {
  // Millisecond remaining before the session ends
  agreedUserIds = [];
  agreedUsers: IUser[] = [];
  endSessionWS: WebSocketManager | null = null;
  sessionId!: string;
  @Prop({ type: Number, required: true })
  remainingTime!: number;
  // Call at the end of the session given time
  @Prop({ type: Function, required: true })
  endOfTimeHandler!: CallableFunction;
  // Minutes of the session time
  @Prop({ type: Number, required: true })
  frame!: number;

  // Interval trigger identifier
  private triggerId = 0;
  // Time remaining in milliseconds
  private remaining = 60000;
  // Last remaining time received from parent
  // It does not update every second
  private lastRemainingReceived = 60000;

  @Watch("remainingTime")
  remainingTimeUpdated(val: number): void {
    this.remaining = val;
  }



  created(): void {
    this.triggerId = window.setInterval(() => {
      this.refreshProgressTime();
    }, 1000);
    this.remaining = this.remainingTime;
    this.lastRemainingReceived = this.remainingTime;
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  refreshProgressTime(): void {
    this.remaining -= 1000;
    if (this.progressInPercentage >= 100) {
      console.log("refreshProgressTime time out")
      this.timeOutTryOut();

      this.$el.remove();
    }
  }

  timeOutTryOut(): void {
    console.log("Ici ce qu'on peut faire quand ça TO")

    console.log("Creation du WS qu'on veut récup"),
      this.endSessionWS = new WebSocketManager(
        `${webSocketUrl}/session/end/${this.sessionId}/${this.userId}`,
        this.onRetryFail,
        () => console.log("endSessionWS error handler")
      );
    console.log(this.endSessionWS);


    this.endOfTimeHandler();
    clearTimeout(this.triggerId);
  }

  onRetryFail(event: CloseEvent): void {
    // Alert user and provide a user-friendly message
    this.setSnack({
      snackText:
        "La connexion WebSocket pour la fin de la tache a échoué après 3 tentatives. Veuillez vérifier votre connexion réseau.",
      snackColor: "error",
      snackClosable: true,
    });
  }

  get progress(): number {
    let frameMilliseconds = this.frame * 60000;
    return frameMilliseconds - this.remaining;
  }

  get progressInPercentage(): number {
    let frameMilliseconds = this.frame * 60000;
    return (this.progress * 100) / frameMilliseconds;
  }

  get load(): boolean {
    return isNaN(this.frame);
  }

  beforeDestroy(): void {
    clearInterval(this.triggerId);
  }
}
